<template>
    <div class="root" :class='$mq'>

        <div class="content" :class='$mq'>
            <h2 class="title">{{title}}</h2>
            <slot></slot>
        </div>
        <div class="button" :class='$mq'>
            <div class="price" :class='$mq'>
                {{price}}₽<br><span>{{oldPrice}}₽</span>
            </div>
            <k-rounded-button
                    class="buy-button" :class='$mq'
                    color="#b66856"
                    text-color="#fff"
                    padding="0.8em 1.6em"
                    @click="tryToBuy"
            >
                Купить пакет
            </k-rounded-button>
            <div class="button-shine" :class='$mq'><KImage :src="require('@/assets/newyear/button-gold.png')" alt="" width="100%"/></div>
        </div>
    </div>

</template>

<script>
    import KRoundedButton from "../../Widgets/KRoundedButton";
    import KImage from "../../Widgets/KImage";

    export default {
        name: "OfferBlock",
        components: {
            KRoundedButton,
            KImage
        },

        props: {
            title: String,
            oldPrice: Number,
            price: Number,
            offerId: Number
        },
        methods: {
            tryToBuy() {
                this.$router.push("/item/"+this.offerId)
            }
        }

    }
</script>

<style scoped>
    div.root {
        width: 100%;
        margin-top: 70pt;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
        .root.halftablet {
            margin-top: 60pt;
        }
        .root.mobile {
            margin-bottom: 20pt;
        }
        
    h2.title {
        background-color: #2e0530;
        display: inline-block;
        line-height: 0.7;
        font-size: 3em;
        color: #fff;
        margin: 0 auto 10pt auto;
        position: relative;
        top: -10px;
        box-shadow: 0px 0px 30px 50px #2e0530;
        font-family: 'Kudryashev', serif;
        z-index: 0;
    }
        .content.tablet h2.title {
            font-size: 2.8em;
            margin-bottom: 5pt;
            box-shadow: 0px 0px 30px 20px #2e0530;
            padding: 0 10px;
        }
        .content.halftablet h2.title,
        .content.mobile h2.title {
            display: inline-block;
            font-size: 2.5em;
            top: -20pt;
            line-height: 0.9;
            box-shadow: 0px 0px 10px 20px #2e0530;
            text-align: left;
            margin: 0 20pt 0 20pt;
        }
        .content.mobile h2.title {
            font-size: 2.2em;
            margin: 0;
            box-shadow: 0px 0px 10px 10px #2e0530;
            text-align: center;
        }
    div.content {
        width: 100%;
        border: 1px solid #ce955e;
        margin: 0 auto;
        padding: 0 30pt 50pt 30pt;
        text-align: center;
        box-sizing: border-box;
        font-size: 1em;
    }
   
        .content.tablet,
        .content.halftablet {
            font-size: 0.8em;
        }
        .content.halftablet{
            padding: 0 10pt 50pt 10pt;
        }
        .content.mobile{
            padding: 0 15pt 40pt 15pt;
            font-size: 0.7em;
        }


    div.button {
        margin: 0 auto;
        display: flex;
        align-items: baseline;
        position: relative;
        top: -50px;
        box-shadow: -0px -50px 50px 90px #2e0530;
        border-radius: 100px;
        background-color: #2e0530;
        z-index: 1;
        position: relative;
    }
        .button.halftablet{
            box-shadow: 0px 0px 50px 5px #2e0530;
            padding: 10pt;
        }
        .button.mobile{
            box-shadow: 0px 0px 30px 5px #2e0530;
            top: -30px;
        }
    div.price {
        padding-right: 20px;
        font-size: 2.5em;
        text-align: center;
        line-height: 0.6;
    }
        .price.halftablet {
            font-size: 2em;
        }
         .price.mobile {
            font-size: 1.8em;
            padding-right: 0.3em;
        }
    div.price span {
        font-size: 0.55em;
        color: #ce955e;
        stroke: #ce955e;
        position: relative;
        text-decoration: line-through;
    }
    .buy-button {
        position: relative;
        z-index: 1;
    }
        .buy-button.mobile,
        .buy-button.halftablet {
            font-size: 0.8em;
            padding: 1em 1.2em!important;
        }
    .button-shine {
        position: absolute;
        width: 171px;
        top: -40px;
        z-index: 0;
        right: -70px;
    }
     .button-shine.mobile {
         width: 120px;
         top: -30px;
         right: -40px;
     }
</style>