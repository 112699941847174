<template>
    <svg xmlns="http://www.w3.org/2000/svg"
         :width="width"
         :height="height"
         viewBox="0 0 64.59 53">
        <g id="Layer_2" data-name="Layer 2"><g :style="getStyle" id="Isolation_Mode" data-name="Isolation Mode"><path class="cls-1" d="M1.28,18.6c-2.1-5.7.1-9.7,6-11.1s10.4,6.8,10.4,6.8.2-8.9,7.6-10.4,9.1,4.6,7.9,9c-1.5,5.2-11.4,22-13.8,22.3C19.48,35.2,3.38,24.3,1.28,18.6Z"/><path class="cls-1" d="M26,22.6c-2.3,3.8-4.7,7.3-5.7,7.4"/><path class="cls-1" d="M27.58,6.2c3.2.7,3.9,4.2,3.1,6.9a24.45,24.45,0,0,1-1.8,4.1"/><path class="cls-1" d="M33.38,48.8c5.2-1.4,7.3-1.6,9.1-7.2s3.3-8.8.1-10.4c-2.6-1.2-6.8-3.7-8.4-4.6a.61.61,0,0,0-.9.3l-.6,1.3"/><path class="cls-1" d="M6.88,52.5c14.7-.7,21.1.3,24.3-1.4s8.1-13.8,7.5-16.3c-.5-2-5.2-6-7.3-7.7-.4-.3-1.1,0-1,.5a6.33,6.33,0,0,0,1.9,4c2.5,2.4,2.7,4.5,2.7,4.5s-6.3,6.6-6.8,8.4-8.6-1.2-14.1,1.6"/><path class="cls-1" d="M40.38,45c4-.3,7.3-1,8.3-10.1a76,76,0,0,0,0-16.4.65.65,0,0,0-.6-.6c-1-.1-3.1.2-2.7,4.6A77.7,77.7,0,0,1,45,35"/><path class="cls-1" d="M48.88,33.5c4.3-1.3,5.2-.5,7.8-5.3a11.6,11.6,0,0,1,6.2-5.1A.86.86,0,0,1,64,24.3c-1,2-2.7,5.2-4.1,8.2-2.2,4.6-12.3,7.6-12.3,7.6"/><path class="cls-1" d="M32.68,22.4c5.2-2.2,6.4-3.4,8.8-4.3a.86.86,0,0,1,1.1,1.2c-1.5,3.4-6.9,7.8-6.9,7.8"/><path class="cls-1" d="M4.28,41c2.3-2.6,4.1-4.5,5.8-6.1"/><path class="cls-1" d="M54.78,0V2.3"/><path class="cls-1" d="M56.08,4.7h1.3"/><path class="cls-1" d="M54.48,8.7V6.4"/><path class="cls-1" d="M52.08,5h-1.3"/></g></g>
    </svg>
</template>

<script>
    export default {
        name: "KIHandFlower",
        props: {
            width: {
                type: [Number, String],
                default: 18
            },
            height: {
                type: [Number, String],
                default: 18
            },
            iconColor: {
                type: String,
                default: '#ffb1a4'
            }
        },
        computed: {
            getStyle () {
                return 'stroke: '+this.iconColor
            }
        }
    }
</script>

<style scoped>
    .cls-1 {
        fill: none;
        stroke-miterlimit: 10
    }
</style>