<template>
    <div class='main-container' :class='$mq'>
        <div class="main-screen-block" :class='$mq'>
            <div class="main-screen" :class='$mq'>
                <div class="NYtopleft">
                    <KImage :src="require('@/assets/newyear/NYlefttop.png')"  alt="Art doll" width="100%"/>
                </div>
                <div class="NYtop">
                    <KImage :src="require('@/assets/newyear/NYtop.png')"  alt="Art doll" width="100%"/>
                </div>
                <div class="NYright">
                    <KImage :src="require('@/assets/newyear/NYright.png')"  alt="Art doll" width="100%"/>
                </div>


                <div class="NYmain-img" :class='$mq'>
                    <KImage :src="require('@/assets/newyear/NYmain.jpg')"  alt="Art doll" width="100%"/>
                </div>

                <h1>Научись cоздавать невероятную авторскую <br>
                    <span class="big">КУКЛУ</span><br>
                    <span class="curve">за 2 месяца</span>
                </h1>


                <div class="light big" :class='$mq'></div>
                <div class="light" :class='$mq'></div>
            </div>

            <div class="main-buttons">
                <KRoundedButton class="NY-button" text-color="#fff" color="#C26251" v-scroll-to="'#second'">
                    Я уже делаю кукол
                    <template v-slot:icon>
                        <KIHandHeart icon-color="#FFF" height="50px" width="50px"/>
                    </template>
                </KRoundedButton>

                <p>или</p>

                <KRoundedButton class="NY-button" text-color="#fff" color="#C26251" v-scroll-to="'#first'">
                    Я не делала кукол
                    <template v-slot:icon>
                        <KIHandFlower icon-color="#FFF" height="50px" width="50px"/>
                    </template>
                </KRoundedButton>
            </div>
        </div>

        <offer-block id="first" title="Создайте свою первую куклу" price="3800" old-price="5950" offer-id="10">
            <p class="course-p">Делаете первые шаги в кукольном творчестве? Хотите узнать как слепить красивое личико, сделать куклу подвижной, расписать ее и одеть? Предлагаю вам бестселлер для начинающих мастеров: электронная книга + подробные видеоуроки по лепке лица и росписи куклы. Весь процесс создания будуарной куклы от А до Я в одном пакете.</p>
                <div class="course-block" :class='$mq'>

                    <div class="course-img-block" :class='$mq' @click="playVideo('0K27QtULg4Q')">
                        <div class="ribbon small">
                            <p class="info">224 стр</p>
                        </div>
                        <div class="course-img"><KImage :src="require('@/assets/newyear/book.png')" alt="" width="100%"/></div>
                        <div class="course-bg"><KImage :src="require('@/assets/newyear/courseBG.png')" alt="" width="100%"/></div>
                        <div class="course-light"></div>
                        <p class="text">Cоздайте будуарную куклу по электронной книге <span>2000₽</span></p>
                    </div>

                    <div class="hearts" :class='$mq'>
                        <KImage :src="require('@/assets/newyear/hearts.svg')" alt="" width="100%"/>
                    </div>

                    <div class="course-add-block" :class='$mq'>
                        <SmallOfferBlock
                                price="950"
                                info="3 часа"
                                :src="require('@/assets/newyear/course-child.png')"
                                @click="playVideo('kthPid7qJYQ')"
                        >
                            <h3><span class="top">Слепите</span>детскую голову</h3>
                        </SmallOfferBlock>
                        <SmallOfferBlock
                                price="3000"
                                info="4 часа"
                                :src="require('@/assets/newyear/course-draw.png')"
                                @click="playVideo('GZ3ETJJc46s')"
                        >
                            <h3>Распишите куклу<span class="bottom">сделайте парик</span></h3>
                        </SmallOfferBlock>
                    </div>
                </div>
        </offer-block>
    </div>

    <div class="reviews" :class='$mq'>
        <div class="review-dark left"></div>
        <splide :slides="items" :options="options">
            <splide-slide v-for="(slide, index) in items" :key="index">
                <KImage :src="slide" @click="showImage(slide)" width="100%"></KImage>
            </splide-slide>
        </splide>
        <div class="review-dark right"></div>
        <p>Работы девочек, прошедших курс по будуарной кукле</p>
    </div>

    <div class='main-container' :class='$mq'>
        <offer-block id="second" title="Создайте шарнирную куклу" price="15000" old-price="20000" offer-id="11">
            <p class="course-p">Создание шарнирной куклы, это еще то испытание, поэтому сюда заходят только самые смелые и трудолюбивые и, конечно, те, кто уже имеет опыт в кукольном творчестве. В курсе собрано все от лепки, секретов шарниров и их постановки, сборки куклы до росписи и парика. Все ответы на ваши вопросы по шарнирной кукле тут!</p>
            <div class="course-block" :class='$mq'>
                <div class="course-img-block" :class='$mq' @click="playVideo('veh4wCtjk3k')">
                    <div class="ribbon">
                        <p class="info">381 стр</p>
                        <p class="info second">12 часов</p>
                    </div>
                    <div class="course-img"><KImage :src="require('@/assets/newyear/bjd.png')" alt="" width="100%"/></div>
                    <div class="course-bg"><KImage :src="require('@/assets/newyear/courseBG.png')" alt="" width="100%"/></div>
                    <div class="course-light"></div>
                    <p class="text">Cоздайте шарнирную куклу <span>17500₽</span></p>
                </div>

                <div class="hearts" :class='$mq'>
                    <KImage :src="require('@/assets/newyear/hearts.svg')" alt="" width="100%"/>
                </div>

                <div class="course-add-block" :class='$mq'>
                    <SmallOfferBlock
                            price="4000"
                            info="8 часов"
                            :src="require('@/assets/newyear/course-anatomy.png')"
                            @click="playVideo('LBdTCpwBs2c')"
                    >
                        <h3><span class="top">Слепите</span>анатомичное тело</h3>
                    </SmallOfferBlock>
                    <SmallOfferBlock
                            price="3500"
                            info="8 часов"
                            :src="require('@/assets/newyear/course-cloth.png')"
                            @click="playVideo('unLBRZe17P8')"
                    >
                        <h3><span class="top">Сшейте</span>съемный костюм</h3>
                    </SmallOfferBlock>
                </div>
            </div>
        </offer-block>
    </div>
    <div class="reviews" :class='$mq'>
        <div class="review-dark left"></div>
        <splide :slides="itemsBjd" :options="options">
            <splide-slide v-for="(slide, index) in itemsBjd" :key="index">
                <KImage :src="slide" @click="showImage(slide)" width="100%"></KImage>
            </splide-slide>
        </splide>
        <div class="review-dark right"></div>
        <p>Работы девочек, прошедших курс по шарнирной кукле</p>
    </div>
</template>

<script>
    import SmallOfferBlock from "./SmallOfferBlock";
    import KImage from "../../Widgets/KImage";
    import KRoundedButton from "../../Widgets/KRoundedButton";
    import KIHandFlower from "../../Widgets/Icons/KIHandFlower";
    import KIHandHeart from "../../Widgets/Icons/KIHandHeart";
    import OfferBlock from "./OfferBlock";
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import '@splidejs/splide/dist/css/themes/splide-default.min.css';

    import image1 from "@/assets/newyear/reviews/Beg1.jpg"
    import image2 from "@/assets/newyear/reviews/Beg2.jpg"
    import image3 from "@/assets/newyear/reviews/Beg3.jpg"
    import image4 from "@/assets/newyear/reviews/Beg4.jpg"
    import image5 from "@/assets/newyear/reviews/Beg5.jpg"
    import image6 from "@/assets/newyear/reviews/Beg6.jpg"    
    
    import imageBjd1 from "@/assets/newyear/reviews/Bjd1.jpg"
    import imageBjd2 from "@/assets/newyear/reviews/Bjd2.jpg"
    import imageBjd3 from "@/assets/newyear/reviews/Bjd3.jpg"
    import imageBjd4 from "@/assets/newyear/reviews/Bjd4.jpg"
    import imageBjd5 from "@/assets/newyear/reviews/Bjd5.jpg"
    import imageBjd6 from "@/assets/newyear/reviews/Bjd6.jpg"
    import imageBjd7 from "@/assets/newyear/reviews/Bjd7.jpg"
    export default {
        name: "MainScreen",
        components: {
            KImage,
            KRoundedButton,
            KIHandFlower,
            KIHandHeart,
            OfferBlock,
            Splide,
            SplideSlide,
            SmallOfferBlock
        },
        data () {
            return {
                items: [
                    image1,
                    image2,
                    image3,
                    image4,
                    image5,
                    image6,
                ],                
                itemsBjd: [
                    imageBjd1,
                    imageBjd2,
                    imageBjd3,
                    imageBjd4,
                    imageBjd5,
                    imageBjd6,
                    imageBjd7,
                ],
                options: {
                    rewind: true,
                    perPage: 5,
                    gap: '0.3rem',
                    pagination: false,
                    // cover: true,
                    breakpoints: {
                        450: {
                            perPage: 2,
                        },
                        700: {
                            perPage: 3,
                        },
                        1000: {
                            perPage: 4,
                        },
                    },
                },
            }
        },
        methods: {
            showImage(src) {
                this.$swal.fire({
                    // width: '80%',
                    height: '90%',
                    background: '#eae2e0',
                    html: '<img width="100%" src="'+src+'"/>',
                    showCloseButton: true,
                    showConfirmButton: false,
                    // closeButtonHtml: "",
                })
            },
            playVideo(videoId) {
                let videoWidth = window.innerWidth < 800 ? window.innerWidth : 800
                let videoHeight = videoWidth / 1.77
                this.$swal.fire({
                    width: videoWidth+'px',
                    background: '#eae2e0',
                    html: "<iframe frameborder=\"0\" allowfullscreen=\"1\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" title=\"YouTube video player\" width=\""+videoWidth+"\" height=\""+videoHeight+"\" src=\"https://www.youtube.com/embed/"+videoId+"?autoplay=1&controls=0&rel=0&modestbranding=0&loop=1&enablejsapi=1&origin=https%3A%2F%2Fkibanovadolls.ru&widgetid=1\"></iframe>",
                    showCloseButton: true,
                    showConfirmButton: false,
                    // closeButtonHtml: ""
                })
            }
        }
    }
</script>

<style scoped>
    div.main-container{
        max-width: 950px;
        margin: 0px auto 0 auto;
        padding: 0 20px;
    }

    .main-screen-block {
        margin-bottom: 50px;
        margin-top: -50px;
    }

    .main-screen-block.tablet {
        margin-bottom: 30px;
        margin-top: -20px;
    }
    .main-screen-block.halftablet,
    .main-screen-block.mobile {
        margin-bottom: 20px;
        margin-top: -0px;
    }

    div.main-screen {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }

    div.main-screen.mobile {
        flex-wrap: wrap;
    }

    .main-screen h1 {
        font-size: 3.5em;
        line-height: 0.9;
        margin-bottom: 20px;
        width: 40%;
        position: relative;
        top: -30px;
    }
        .main-screen.laptop h1, 
        .main-screen.tablet h1, 
        .main-screen.halftablet h1 {
            right: 50px;            
        }
        .main-screen.tablet h1 {
            font-size: 2.5em;
            top: 0;
        }
        .main-screen.halftablet h1 {
            font-size: 2em;
            top: 0;
        }
        .main-screen.mobile h1 {
            width: 216px;
            font-size: 1.7em;
            margin-top: 80px;
            margin-bottom: 0px;
        }

        .main-screen h1 .curve {
            font: 50pt/50pt 'TheArtist', sans-serif;
            color: #fff0ee;
            position: relative;
            top: -20px;
            right: -140px;
        }

        .main-screen.tablet h1 .curve, 
        .main-screen.halftablet h1 .curve,
        .main-screen.mobile h1 .curve {
            right: -50px;
        }

        .main-screen.halftablet h1 .curve {
            font-size: 1.3em;
        }
        .main-screen.mobile h1 .curve {
            font-size: 1.3em;
            left: auto;
            right: -40px;
            bottom: 10px;
            top: -25px;
        }

    .NYmain-img {
        width: 520px;
        position: relative;
        top: -60px;
        left: -50px;
        z-index: 0;
        background: white;
    }
        .NYmain-img.tablet{
            width: 50%;
            top: -10px;
            left: 0;
        }
        .NYmain-img.halftablet {
            width: 60%;
            top: -20px;
            left: -10%;
        }
        .NYmain-img.mobile {
            position: absolute;
            width: 300px;
            top: -40px;
            left: auto;
            right: -21%;
        }

    span.big {
        font-size: 1.7em;
        line-height: 1.2;
    }

    .main-buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: -150px auto 0 auto;
        z-index: 999;
        position: relative;
    }
         .main-container.mobile .main-buttons {
             flex-wrap: wrap;
         }
        .main-buttons p{
            margin: 0 20px;
            font-weight: 700;
            z-index: 10;
        }
        .main-container.halftablet .main-buttons p,
        .main-container.mobile .main-buttons p {
            font-size: 0.8em;
            color: rgb(194, 98, 81);
        }
        .main-container.mobile .main-buttons p {
            margin: 10px 0;
        }
        .main-container.tablet .main-buttons {
            margin-top: -80px;
        }        

        .main-container.halftablet .main-buttons,
        .main-container.mobile .main-buttons {
            margin-top: -40px;
        }        
        .main-container.tablet .NY-button {
            font-size: 0.9em;
            font-weight: 600;
            line-height: 1;
            padding: 7px 30px;
        }
        .main-container.halftablet .NY-button, 
        .main-container.mobile .NY-button  {
            font-size: 0.89em;
            font-weight: 600;
            line-height: 1;
            padding: 7px 30px;
        }
        
        .main-container.mobile .NY-button  {
            width: 100%;
        }

    div.main-text {
        color: #fff0ee;
    }

    .reviews {
        width: 80%;
        max-width: 1200px;
        margin: 0 auto;
        position: relative;
        text-align: center;
        font-style: italic;
    }

    .course-p {
        position: relative;
        z-index: 10;
        width: 85%;
        margin: 0 auto 40pt auto;
        text-align: left;
        line-height: 1.3;
    }
        .content.tablet .course-p {
            width: 95%;
            margin-bottom: 30pt;
        }
        .content.halftablet .course-p {
            width: 85%;
            margin-bottom: 30pt;            
        }
        .content.mobile .course-p {
            width: 100%;
            margin-bottom: 1em;    
            top: -1em;
        }

     .course-block {
         display: flex;
         justify-content: space-between;
         align-items: center;
     }
        .course-block.halftablet,
        .course-block.mobile {
            flex-wrap: wrap;
        }

    .NYright, .NYtop, .NYtopleft {
        position: absolute;
    }
        .NYright {
            width: 356px;
            bottom: 80px;
            right: -180px;
        }
            .main-screen.tablet .NYright {
                width: 250px;
                right: -100px;
                bottom: 40px;
            }
            .main-screen.halftablet .NYright,
            .main-screen.mobile .NYright {
                width: 200px;
                right: -90px;
                bottom: 20px;
            }
        .NYtop {
            width: 574px;
            top: -70px;
            right: -210px;
            z-index: 2;
        }
            .main-screen.tablet .NYtop {
                width: 400px;
                right: -15%;
                top: -100px;
            }
            
            .main-screen.halftablet .NYtop,
            .main-screen.mobile .NYtop {
                width: 350px;
                right: -170px;
                top: -100px;
            }

        .NYtopleft {
            width: 312px;
            top: -60px;
            left: -250px;
            z-index: 10;
        }
            .main-screen.tablet .NYtopleft {
                width: 200px;
                left: -100px;
            }
            .main-screen.halftablet .NYtopleft,
            .main-screen.mobile .NYtopleft {
                display: none;
            }

    .light {
        width: 500px;
        height: 500px;
        position: absolute;
        bottom: 0;
        right: -370px;
        z-index: 11;
        background: radial-gradient(circle 230px, rgba(189,79,71,0.3), transparent 100%);
    }
        .light.tablet,
        .light.halftablet {
            bottom: -110px;
            right: -2 50px;
        }
        .light.mobile {
            display: none;
        }

    .light.big {
        width: 900px;
        height: 900px;
        background: radial-gradient(circle 430px, rgba(189, 79, 71, 0.3), transparent 100%);
        top: -450px;
        left: -500px;
    }
        .light.big.mobile {
            width: 200px;
            height: 200px;
            left: 0;
            top:0;
        }

    .course-block {
        position: relative;
        z-index: 11;
    }

    .course-img-block {
        width: 398px;
        position: relative;
        transform: rotate(-7.5deg);
        margin-left: 30pt;
        transition: all 0.3s cubic-bezier(.54, 0, .4, 1.51);
        cursor: pointer;
    }
        .course-img-block.tablet,
        .course-img-block.halftablet,
        .course-img-block.mobile {
            width: 280px;
            margin-left: 10px;
        }
        .course-img-block.halftablet,
        .course-img-block.mobile {
            margin: 0 auto;
             transform: rotate(-5deg);
        }

    .course-img-block .ribbon {
        position: absolute;
        color: #2e0530;
        background: url("~@/assets/newyear/ribbon-big.png") no-repeat;
        background-size: 100%;
        width: 157px;
        height: 82px;
        overflow: visible;
        z-index: 3;
        right: 20pt;
        top: -30pt;
    }
        .course-img-block.tablet .ribbon,
        .course-img-block.halftablet .ribbon {
            width: 130px;
            right: 10pt;
            top: -20pt;
        }
        .course-img-block.mobile .ribbon {
            width: 130px;
            right: 3pt;
            top: -26pt;
        }

    .course-img-block .ribbon.small {
        background: url("~@/assets/newyear/ribbon.png") no-repeat;
        background-size: 100%;
        width: 110px;
        height: 46px;
        top: -10px;
        right: 20px;
    }
        .course-img-block.tablet .ribbon.small,
        .course-img-block.halftablet .ribbon.small {
            top: -10px;
            right: 5pt;
        }
        .course-img-block.mobile .ribbon.small {
            top: -20px;
            right: 2pt;
        }

    .course-img-block .ribbon.small .info {
        top: 17pt;
        left: 15px;
    }
    .course-img-block.tablet .ribbon.small .info,
    .course-img-block.halftablet .ribbon.small .info,
    .course-img-block.mobile .ribbon.small .info {
        top: 19pt;
    }
    

    .course-img-block .ribbon p.info {
        background: url("~@/assets/newyear/ribbon_icons.svg") no-repeat;
        background-size: 15px;
        background-position: 5pt 4pt;
        font-size: 0.75em;
        font-weight: 700;
        position: absolute;
        padding-left: 20pt;
        top: 33pt;
        left: 18pt;
    }
        .course-img-block.tablet .ribbon p.info,
        .course-img-block.halftablet .ribbon p.info,
        .course-img-block.mobile .ribbon p.info {
            background-position: 13pt 3pt;
            padding-left: 28pt;
            left: 8pt;
            font-size: 0.85em;
            top: 29pt;
        }
        .course-img-block.mobile .ribbon p.info {
            left: 14pt;
        }
        
        .ribbon p.info.second {
            background-position-y: -21px;
            top: 45pt;
        }
            .course-img-block.tablet .ribbon p.info.second,
            .course-img-block.halftablet .ribbon p.info.second,
            .course-img-block.mobile .ribbon p.info.second {
                top: 38pt;
                background-position-y: -22px;
            }
        .course-img-block p.text{
            position: absolute;
            width: 300px;
            bottom: 20pt;
            left: 50%;
            transform: translateX(-50%);
            line-height: 1.1;
        }
            .course-img-block.tablet p.text {
                bottom: 10pt;
                width: 250px;
            }
            .course-img-block.halftablet p.text {
                bottom: 10pt;
            }
            .course-img-block.mobile p.text {
                bottom: 2pt;
                width: 200px;
            }

        .course-img-block p.text span {
            font-weight: 700;
            color: #D08353;
            text-decoration: line-through;
        }
        .course-img{
            position: relative;
            z-index: 1;
            transition: all 0.3s cubic-bezier(.54, 0, .4, 1.51);
        }
            .course-img:hover {
                transform: scale(1.05);
            }
        .course-bg {
            position: absolute;
            width: 493px;
            display: block;
            bottom: 60px;
            left: -60px;
            z-index: 0;
        }
            .course-img-block.tablet .course-bg,
            .course-img-block.halftablet .course-bg {
                width: 330px;
                left: -40px;
            }
            .course-img-block.mobile .course-bg{
                width: 250px;
                left: 50%;
                transform: translateX(-53%);
            }
        .course-light {
            width: 600px;
            height: 600px;
            position: absolute;
            top: -60px;
            left: -100px;
            background: radial-gradient(circle 250px, rgba(189, 79, 71, 1), transparent 100%);
        }
            .course-img-block.tablet .course-light, 
            .course-img-block.halftablet .course-light {
                width: 400px;
                height: 400px;
                top: -40px;
                left: -70px;
                background: radial-gradient(circle 190px, rgba(189, 79, 71, 1), transparent 100%);
            }
            .course-img-block.mobile .course-light {
                width: 350px;
                height: 350px;
                top: -40px;
                left: 50%;
                transform: translateX(-50%);
                background: radial-gradient(circle 170px, rgba(189, 79, 71, 1), transparent 100%);
            }
            

    .course-add-block {
        width: 40%;
        margin-top: -30pt;
    }
        .course-add-block.tablet {
            width: 45%;
            margin-top: -10pt;
        }
        .course-add-block.halftablet,
        .course-add-block.mobile {
            width: 100%;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            margin-top: 20pt;
            align-items: center;
        }

    .hearts {
        width: 70pt;
        position: absolute;
        left: 56%;
        transform: translateX(-50%);
    }
        .hearts {
            display: none
        }

    h3 {
        font-size: 2em;
        line-height: 1;
        position: relative;
        top: -50pt;
    }
        .course-add.tablet h3,
        .course-add.halftablet h3,
        .course-add.mobile h3 {
            top: -35pt;
        }
        .course-add.mobile h3 {
            top: -20pt;
        }

    h3 span {
        font-family: 'TheArtist', sans-serif;
        font-size: 1.3em;
        position: absolute;
    }
    h3 span.top {
        top:-20pt;
        left: 15pt;
    }
        .course-add.tablet h3 span.top {
            left: 5pt;
        }
        .course-add.halftablet h3 span.top {
            left: 25pt;
        }
        .course-add.mobile h3 span.top {
            left: 0pt;
            top: -14pt;
        }
    h3 span.bottom {
        bottom: -25pt;
        right:0;
    }
        .course-add.mobile h3 span.bottom {
            bottom: -18pt;
        }

    .review-dark {
        width: 150px;
        height: 100%;
        position: absolute;
        z-index: 1;
        top:0;
        pointer-events: none;
    }
        .reviews.halftablet .review-dark {
            width: 100px;
        }
        .reviews.mobile .review-dark {
            width: 50px;
        }
    .review-dark.left {
        background: linear-gradient(270deg, rgba(46,5,48,0) 0%, rgba(46,5,48,1) 100%);
        left:0;
    }
    .review-dark.right {
        background: linear-gradient(90deg, rgba(46,5,48,0) 0%, rgba(46,5,48,1) 100%);
        right:0;
    }

    .reviews.tablet,
    .reviews.halftablet,
    .reviews.mobile {
        width: 90%;
    }

    .reviews.halftablet,
    .reviews.mobile {
        width: 90%;
        margin-top: -20pt;
    }

    .reviews p{
        margin-top: 10pt;
    }
        .reviews.tablet p,
        .reviews.halftablet p,
        .reviews.mobile p {
            font-size: 0.8em;
            line-height: 1.2;
        }
        .reviews.mobile p {
            font-size: 0.7em;
            line-height: 1.2;
        }

        .reviews.halftablet p,
        .reviews.mobile p {
            width: 80%;
            margin: 10pt auto 0 auto;
        }

</style>