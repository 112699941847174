<template>
    <div class="course-add" :class='$mq'>
        <KImage :src="src" alt="" width="100%"/>
        <slot></slot>
        <p class="price">{{price}}₽</p>
        <div><p class="info" :class='$mq' >{{info}}</p></div>
    </div>
</template>

<script>
    import KImage from "../../Widgets/KImage";
    export default {
        name: "SmallOfferBlock",
        components: {
            KImage
        },
        props: {
            price: Number,
            info: String,
            title: String,
            src: Object
        }
    }
</script>

<style scoped>
    .course-add {
        position: relative;
        margin-bottom: -30pt;
        cursor: pointer;
        transition: all 0.3s cubic-bezier(.54, 0, .4, 1.51);
    }
        .course-add.tablet,
        .course-add.halftablet,
        .course-add.mobile {
            margin-bottom: -10pt;
        }
        .course-add.halftablet  {
            width: 80%;
            max-width: 300px;
        }
        .course-add.mobile  {
            width: 100%;
            max-width: 300px;
        }
   
        .course-add:hover {
            transform: scale(1.05);
        }

    .course-add p {
        position: absolute;
        font-size: 0.75em;
        font-weight: 700;
        right: 30pt;
    }
         .course-add.tablet p,
         .course-add.halftablet p,
         .course-add.mobile p {
             font-size: 0.85em;
             right: 13pt;
         }
    .course-add p.price {
        bottom: 75pt;
        color: #ce955e;
        text-decoration: line-through;
    }  
        .course-add.tablet p.price,
        .course-add.halftablet p.price {
            bottom: 55pt;
        }
        .course-add.mobile p.price {
            bottom: 37pt;
        }

    .course-add div {
        top: -13px;
        right: 0;
        color: #2e0530;
        position: absolute;
        background: url("~@/assets/newyear/ribbon.png") no-repeat;
        background-size: 100%;
        width: 110px;
        height: 46px;
        overflow: visible;
    }
        .course-add.tablet div,
        .course-add.halftablet div {
            top: -10pt;
        }
        .course-add.mobile div {
            top: -15pt;
        }

    div p.info {
        top: 22px;
        right: 15px;
        padding-left: 20px;
        background: url("~@/assets/newyear/ribbon_icons.svg") no-repeat;
        background-size: 15px;
        background-position-y: -21px;
    }
        div p.info.tablet,
        div p.info.halftablet,
        div p.info.mobile {
            top: 25px;
            background-position-y: -24px;
        }
        /*.course-add p.info::after {*/
        /*    content: url("~@/assets/newyear/ribbon.png");*/
        /*    width: 110px;*/
        /*    display: block;*/
        /*    top:0;*/
        /*    right:0;*/
        /*}*/
</style>